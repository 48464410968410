import React from "react";

function NotFoundPage() {
  return (
    <div>
      <h1>404: Page not found</h1>
      <p>The page you requested could not be found.</p>
    </div>
  );
}

export default NotFoundPage;
